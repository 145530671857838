import request from "../../utils/api";

export const getCepProvinces = () => request(`locations/provinces`);
export const getCepCities = (id) =>
  request(
    `locations/provinces/${id}/cities/serviceable`
  );
// commented as per CEP-806
// export const getCepBarangays = (id) =>
// request(`locations/cities/${id}/barangays?filtered=true`);
export const getCepBarangays = (id) =>
  request(`locations/cities/${id}/barangays`);

export const createCepDraftBooking = (body) =>
  request(`pbookings/bookings/`, { method: "POST", body });

export const updateCepDraftBooking = (body) =>
  request(`pbookings/bookings/`, { method: "PATCH", body });

export const getLocalTime = () => request(`get-time-zone?key=KZRHVKLMCDMW&format=json&by=zone&zone=Asia/Manila`,
  {},
  'https://api.timezonedb.com/v2.1/');

export const getPortCodes = (body) =>
  request(`locations/port-codes/coverage?provinceName=${body.provinceName}&cityName=${body.cityName}`);

export const getCepPackages = (id) => request(`pbookings/bookings/${id}/packages`, {});

export const getCepBookingDetails = (id) => request(`pbookings/bookings/${id}`, {});

export const getProfile = (id) =>
  request(`pbookings/accounts/personnel/verification/${id}`);

export const createCepPackage = (body) => {
  return request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: "POST", body: body.packages });
}

export const updateCepPackage = (body) => {
  return request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: 'PATCH', body: body.packages });
}

export const cepBulkUpload = (body, bookingNumber) => {
  return request(`pbookings/bookings/${bookingNumber}/packages/validation`, { method: "POST", body });
}

export const cancelCepPackages = (body) => {
  return request(`pbookings/package/cancel`, { method: "POST", body });
}

export const deleteCepPackages = (body) => {
  return request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: "DELETE", body: body.listOfPackages });
}


  // export const getLocalTime = () => request(`get-time-zone?key=${process.env.REACT_APP_TIMEZONEDB_KEY}&format=json&by=zone&zone=Asia/Manila`,
  // {},
  // 'https://api.timezonedb.com/v2.1/');

