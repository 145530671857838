export const actionTypes = {
  GET_PACKAGES: "GET_PACKAGES",
  GET_PACKAGES_SUCCESS: "GET_PACKAGES_SUCCESS",
  GET_PACKAGES_FAILED: "GET_PACKAGES_FAILED",

  GET_PACKAGES_STATUS: "GET_PACKAGES_STATUS",
  GET_PACKAGES_STATUS_SUCCESS: "GET_PACKAGES_STATUS_SUCCESS",
  GET_PACKAGES_STATUS_FAILED: "GET_PACKAGES_STATUS_FAILED",

  CREATE_PACKAGE: "CREATE_PACKAGE",
  CREATE_PACKAGE_SUCCESS: "CREATE_PACKAGE_SUCCESS",
  CREATE_PACKAGE_FAILED: "CREATE_PACKAGE_FAILED",

  UPDATE_PACKAGE: "UPDATE_PACKAGE",
  UPDATE_PACKAGE_SUCCESS: "UPDATE_PACKAGE_SUCCESS",
  UPDATE_PACKAGE_FAILED: "UPDATE_PACKAGE_FAILED",

  FINALIZE_PACKAGE: "FINALIZE_PACKAGE",
  FINALIZE_PACKAGE_SUCCESS: "FINALIZE_PACKAGE_SUCCESS",
  FINALIZE_PACKAGE_FAILED: "FINALIZE_PACKAGE_FAILED",

  DELETE_PACKAGE: "DELETE_PACKAGE",
  DELETE_PACKAGE_SUCCESS: "DELETE_PACKAGE_SUCCESS",
  DELETE_PACKAGE_FAILED: "DELETE_PACKAGE_FAILED",

  RESET_PACKAGE_ACTION_TYPE: "RESET_PACKAGE_ACTION_TYPE",
  RESET_PACKAGE_LIST: "RESET_PACKAGE_LIST",

  GET_NCR_DETAILS: "GET_NCR_DETAILS",
  GET_NCR_DETAILS_SUCCESS: "GET_NCR_DETAILS_SUCCESS",
  GET_NCR_DETAILS_FAILED: "GET_NCR_DETAILS_FAILED"
};

export const actionCreators = {
  getPackages: id => ({ type: actionTypes.GET_PACKAGES, id }),
  getPackagesStatus: id => ({ type: actionTypes.GET_PACKAGES_STATUS, id }),
  createPackage: (id, body) => ({ type: actionTypes.CREATE_PACKAGE, id, body }),
  updatePackage: (id, body) => ({ type: actionTypes.UPDATE_PACKAGE, id, body }),
  finalizePackage: (id, body) => ({ type: actionTypes.FINALIZE_PACKAGE, id, body }),
  deletePackage: (id, bookingNumber) => ({
    type: actionTypes.DELETE_PACKAGE,
    id,
    bookingNumber
  }),
  resetPackageActionType: () => ({
    type: actionTypes.RESET_PACKAGE_ACTION_TYPE
  }),
  resetPackagesList: () => ({ type: actionTypes.RESET_PACKAGE_LIST }),
  getNCRDetails:(body) => ({type: actionTypes.GET_NCR_DETAILS, body})
};

const initialState = {
  actionType: "",
  errors: null,
  isLoading: false,
  isPackageFinished: false,
  isPackageLoading: false,
  packages: [],
  ncrDetails:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PACKAGES:
    case actionTypes.GET_PACKAGES_STATUS:
    case actionTypes.CREATE_PACKAGE:
    case actionTypes.DELETE_PACKAGE:
    case actionTypes.UPDATE_PACKAGE:
    case actionTypes.FINALIZE_PACKAGE:
    case actionTypes.GET_NCR_DETAILS:  
      return Object.assign({}, state, {
        isLoading: true,
        isBookingFinalized: false,
        isPackageFinished: false,
        isPackageLoading: true,
      });



    case actionTypes.GET_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isPackageLoading: true,
        packages: action.payload
      });

      case actionTypes.GET_PACKAGES_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isPackageLoading: false,
        packages: state.packages.map((item) => {
          let match = action.payload.filter(
            (status) => item.trackingNumber === status.trackingNumber
          );

          return {
            ...item,
            status: match[0].status,
          };
        }),
      });

    case actionTypes.CREATE_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isPackageFinished: true,
        actionType: action.type
      });

    case actionTypes.UPDATE_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type
      });

    case actionTypes.FINALIZE_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type
      });

    case actionTypes.DELETE_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.DELETE_PACKAGE_SUCCESS
      });

    case actionTypes.RESET_PACKAGE_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: ""
      });

    case actionTypes.RESET_PACKAGE_LIST:
      return Object.assign({}, state, {
        packages: [],
        isPackageLoading: true,
      });

      case actionTypes.GET_NCR_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        ncrDetails: action.payload
      });

    case actionTypes.GET_PACKAGES_FAILED:
    case actionTypes.GET_PACKAGES_STATUS_FAILED:
    case actionTypes.CREATE_PACKAGE_FAILED:
    case actionTypes.DELETE_PACKAGE_FAILED:
    case actionTypes.GET_NCR_DETAILS_FAILED:  
      return Object.assign({}, state, {
        isLoading: false,
        isPackageLoading: false,
        errors: action.error.message,
        actionType: action.type
      });

    default:
      return state;
  }
};
